<template>
  <v-container class="bg-glass-effect py-0 px-0" fluid>
    <BackArrow class="" v-if="isLoadedBeneficiary" :route="'wallet'"/>

    <v-row v-if="!isLoadedBeneficiary" justify="center" style="height: 100%">
      <v-col cols="6" align="center" align-self="center">

      </v-col>
    </v-row>

    <v-row v-else class="px-3 my-10">
      <v-col class="pb-4 pt-4 mx-3" cols="12">
        <h2 class="px-0 mx-0" style="color: #532E88">Carteirinhas {{ getBenefitName }}</h2>
        <p class="body-1 pt-2" v-if="beneficiaryContractedPlans && beneficiaryContractedPlans.length">Selecione uma das carteirinhas abaixo.</p>
        <p class="body-1 pt-2" v-else>Não existem carteirinhas.</p>
      </v-col>

      <v-col cols="12" v-if="beneficiaryContractedPlans && beneficiaryContractedPlans.length">
        <v-card class="mx-3 primary" rounded="lg">
          <v-card-text class="px-0">
            <v-list>
              <v-subheader><h3>Titular</h3></v-subheader>
              <v-list-item v-for="(bcp, index) in beneficiaryContractedPlans" :key="index" @click="onClickCard(bcp)">
                <v-list-item-icon>
                  <v-card width="60" height="35" :style="selectBackgroundCard(bcp.cardImage)"></v-card>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="getBeneficiaryName(bcp)"></v-list-item-title>

                  <v-list-item-subtitle v-text="getCarrierName(bcp)"></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon>
                    <v-icon color="primary lighten-1">fas fa-angle-right</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-list v-if="dependentContractedPlans && dependentContractedPlans.length">
              <v-subheader><h3>Dependente</h3></v-subheader>
              <v-list-item v-for="(dcp, index) in dependentContractedPlans" :key="index" @click="onClickCard(dcp)">
                <v-list-item-icon>
                  <v-card width="60" height="35" :style="selectBackgroundCard(dcp.cardImage)"></v-card>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="getBeneficiaryName(dcp)"></v-list-item-title>

                  <v-list-item-subtitle v-text="getCarrierName(dcp)"></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon>
                    <v-icon color="primary lighten-1">fas fa-angle-right</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import BackArrow from '@/components/covid/back-arrow/index'
  import { mapMutations } from "vuex";
  export default {
    name: "walletList",
    data: () => ({
      isLoadedBeneficiary: false,
      beneficiaryContractedPlans: [],
      dependentContractedPlans: [],
      selectedItem: 1,
      items: [
        { text: 'Real-Time', icon: 'mdi-clock' },
        { text: 'Audience', icon: 'mdi-account' },
        { text: 'Conversions', icon: 'mdi-flag' },
      ],
    }),
    components: {
      BackArrow,
    },
    async created () {
      this.initParamsFromCards();
      this.isLoadedBeneficiary = true;
    },
    computed: {
      getBenefitName() {
        return this.beneficiaryContractedPlans && this.beneficiaryContractedPlans.length ? `- ${this.beneficiaryContractedPlans[0].benefitName}` : ''
      },
    },
    methods: {
      ...mapMutations(["loading"]),
      getBeneficiaryName(beneficiaryContractedPlan) {
        return beneficiaryContractedPlan ? beneficiaryContractedPlan.personName : '-'
      },
      getCarrierName(beneficiaryContractedPlan) {
        return beneficiaryContractedPlan ? beneficiaryContractedPlan.carrierName : '-'
      },
      initParamsFromCards() {
        if(this.$route.params && this.$route.params.beneficiaryContractedPlans) {
          this.beneficiaryContractedPlans = this.$route.params.beneficiaryContractedPlans;
        }

        if(!this.beneficiaryContractedPlans || (this.beneficiaryContractedPlans && this.beneficiaryContractedPlans.length === 0)) {
          this.$router.push({ name: 'wallet' })
        } else {
          this.beneficiaryContractedPlans.forEach((bcp) => {
            if(bcp.dependents && bcp.dependents.length) {
              this.dependentContractedPlans = this.dependentContractedPlans.concat(bcp.dependents);
            }
          });
        }
      },
      selectBackgroundCard(cardImage) {
        if(cardImage){
          return { backgroundImage: 'url(\'data:image;base64,'+cardImage+'\')', backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat', backgroundColor: 'transparent'}
        } else {
          return { background: 'linear-gradient(135deg, rgba(83, 46, 136, 1) 40%, rgba(108, 88, 133, 1) 100%)' }
        }
      },
      onClickCard(beneficiaryContractedPlan) {
        const cards = [];
        cards.push(beneficiaryContractedPlan);

        this.$router.push({
          name: 'walletDetailCards',
          params: { cards: cards, style: this.selectBackgroundCard(beneficiaryContractedPlan.cardImage), fromRouteBcp: this.beneficiaryContractedPlans }
        });
      }
    }
  }
</script>

<style scoped>
/*background effect blur*/
.bg-glass-effect{
  background: rgba(255,255,255,.8) !important;
  mix-blend-mode: normal !important;
  backdrop-filter: blur(100px) !important;
  height: 100vh;
}
</style>


